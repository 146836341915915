import styled from "@emotion/styled/macro";
import { useSelector } from "react-redux";
import useCirugia from "../../hooks/useCirugia";
import dateFormat from "../../shared/dateFormatCalendar";
import { CardBody, CardTitle, CardWrapper } from "../Atoms/Card";
import CardInfo from "../Atoms/CardInfo";
import useDateAndTimeFormatter from "../../hooks/useDateAndTimeFormatter";



const Comments = ({
    cirugiaId,
    comments,
    close,
})=>{
    console.log(comments)
    const {user} = useSelector(state=>state.auth);
    const cirugia = useCirugia(cirugiaId);
    const {dateFormat} = useDateAndTimeFormatter();

    if(cirugia){
        comments = cirugia.comentarios
    }
     
    console.log(comments)

    return (
        

        
        <StyledCommentWrapper>
            <CardTitle>Comentarios</CardTitle>
            {comments?.length === 0 &&
                <CardInfo>
                    No hay comentarios. 
                </CardInfo>
            }
            <StyledCommentBody>
            {
                comments?.map((comment, index)=>{
                    const userIsAuthor = user._id === comment.autor._id
                    console.log(userIsAuthor);
                    return(
                    <Comment key={`${comment} ${index}`} userIsAuthor={userIsAuthor}>
                        <Author>{`${comment.autor.firstname} ${comment.autor.lastname} ${dateFormat(comment.fecha)}`}</Author>
                        {comment.comentario}
                    </Comment>
                    )                    
                })
            }
            </StyledCommentBody>
        </StyledCommentWrapper>
        
    )

}

const lightGreen = '#c8e6c9'
const lightBlue = '#bbdefb'





const Wrapper = styled.div`
    border: 1px solid ${p=>p.theme.palette.grey[200]};
    background-color: #ffff;
    border-radius: 8px;
    max-width: 400px;
    height: 100%;
    padding: 8px;
    display:flex;
    flex-direction: column;
    margin-top: auto;
    box-shadow: 0.2px 0.3px 0.4px hsl(0deg 0% 63% / 0.41),
    0.8px 1px 1.4px -0.8px hsl(0deg 0% 63%/ 0.41),
    2px 2.5px 3.6px -1.7px hsl(0deg 0% 63% / 0.41),
    4.9px 6.2px 8.9px -2.5px hsl(0deg 0% 63% / 0.41);
    overflow-y:auto;
    
`

const Title = styled.div`
    width:100%;
    height:100%;
    padding: 8px 18px 18px;
    font-size: 1.5rem;
    font-weight: 600;

`

const StyledCommentWrapper = styled(CardWrapper)`
    
    flex:1;

    @media ${p=>p.theme.media.tablet}{
        padding-left: 18px;
        padding-right: 18px;
        min-width: 400px;
        min-height: 300px;
    }
    
`

const StyledCommentBody = styled(CardBody)`
    display:flex;
    flex-direction:column;
    max-height: 300px;
    overflow-y: auto;

`

const Comment = styled.div`    
    border-radius: 12px;
    padding: 8px;
    width: 300px;
    margin-bottom: 8px;
    font-size: .875rem;
    align-self: ${p=>p.userIsAuthor? 'flex-end': 'flex-start'};
    background-color: ${p=>p.userIsAuthor? lightBlue: lightGreen};
    box-shadow: 0.3px 0.5px 0.7px hsl(0deg 0% 63% / 0.34),
    0.4px 0.8px 1px -1.2px hsl(0deg 0% 63% / 0.34),
    1px 2px 2.5px -2.5px hsl(0deg 0% 63% / 0.34);
`

const Author = styled.p`
    margin:0px;
    margin-bottom: 4px;
    font-size: .65rem;
`

export default Comments;