import styled from '@emotion/styled/macro';
import useMediaQuery from '../../../hooks/useMediaQuery';
import formatDate from '../../../shared/dateFormatter';
import formatTime from '../../../shared/timeFormatter';
import { CardBody, CardTitle, CardWrapper } from '../../Atoms/Card';
import LabelValue from '../../Atoms/LabelValue';
import useDateAndTimeFormatter from '../../../hooks/useDateAndTimeFormatter';





const Historia = ({
    cirugia
})=>{

    const isLaptop = useMediaQuery('laptop');
    const {formatTime} = useDateAndTimeFormatter()

    console.log(isLaptop)

    const compare =(a,b)=>{
        if (a.fecha < b.fecha) return 1
        if (a.fecha > b.fecha) return -1
    }

    

    const historia = [...cirugia?.historia]
    
    historia.sort(compare)
    
    

    return (
                
            <StyledCardWrapper>
                <CardTitle>Historia</CardTitle>
                <StyledCardBody>
                    {
                        historia.map((accion, index)=>{
                            const firstname = accion.user.firstname
                            const lastname = accion.user.lastname
                            const date = formatDate(accion.fecha)
                            const time = formatTime(accion.fecha)

                            return(
                                <HistoriaWrapper key={`${accion} ${index}`}>
                                    <div>{accion.accion}</div>
                                    <Author>{`${firstname} ${lastname}, ${date} a las ${time}`}</Author>
                                </HistoriaWrapper>
                            )
                                
                        })
                    }
                    
                </StyledCardBody>
            </StyledCardWrapper>

            
        
    )
}

const StyledCardWrapper = styled(CardWrapper)`
    flex:1;
`

const HistoriaWrapper = styled.div`
    font-size: 0.875rem;
    
`

const StyledCardBody = styled(CardBody)`
    display:flex;
    flex-direction:column;
    gap: 8px;
    max-height: 300px;
    overflow-y: auto;

`

const Author = styled.p`
    margin:0px;
    margin-top: 2px;
    font-size: .70rem;
    color: ${p=>p.theme.palette.grey[700]};
`




export default Historia;
